@import "./assets/varibles.scss";
@import "./assets/responsive.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,400;1,500;1,600;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: none;
  appearance: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  font-feature-settings: "clig" off, "liga" off;
  font-style: normal;
  font-size: 0.9rem;
  line-height: normal;
}
::-webkit-scrollbar {
  width: 0.2rem;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  transition: $Transition;
}
::-webkit-scrollbar-thumb:hover {
  background-color: $Secound-color;
}
.container {
  width: $Container-width-lg;
  max-width: 1800px;
  margin: 0 auto;
  @include md {
    width: $Container-width-md;
  }
}
section {
  width: 100%;
  margin-bottom: 2rem;
}
h1,
h2,
h3,
h4,
h5 {
  line-height: 1.3;
}
h1 {
  font-size: 3rem;
  margin: 1rem 0;
}
h2 {
  font-size: 1.7rem;
  margin: 1rem 0;
}
h3 {
  font-size: 1.1rem;
  margin: 0.8rem 0 0.5rem;
}
h4 {
  font-size: 1rem;
}
a {
  transition: $Transition;
  color: $White-color;
}
img {
  display: block;
  object-fit: cover;
}

.platform{
  font-size: 25px;
  text-align: center;
  width: 100%;
  
}