$Container-width-lg: 82%;
$Container-width-md: 88%;
$Form-width: 40%;
$Main-color: #da6727;
$Secound-color: #433578;
$White-color: #fff;
$Light-Gray-200: #a7a7a7;
$Light-Gray-400: #6c6c6c;
$Transition: all 300ms ease;
$Ligh-Black: #040404;
$Black: #040404;
$Font-colour: #253439;
$button-colour: #253439;
$Font-colour-light: #6a6a6a;
$Background-color: rgba(246, 244, 241, 0.5);
