@import "../../assets/varibles.scss";
@import "../../assets/responsive.scss";
.section5 {
  height: 100%;
  padding: 3rem 0;
  .section5Container {
    h2 {
      color: $Font-colour;
      font-size: 22px;
      font-weight: 700;
      @include lg {
        text-align: left;
      }
      @include md { 
        text-align: center;
      }
    }
    .item-container {
      display: flex;
      justify-content: space-between;
      @include md {
        flex-direction: column;
        gap: 1rem;
      }
      .left {
        flex: 1;
        form {
          background-color: $Background-color;
          width: 100%;
          padding: 1rem 2rem;
          display: flex;
          flex-direction: column;
          gap: 0.7rem;
          border-radius: 0.5rem;
          .input {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            input,
            textarea {
              border-radius: 0.5rem;
              border: none;
              padding: 0 10px;
            }
            input {
              height: 3rem;
            }
            textarea {
              padding-top: 0.5rem;
            }
            label {
              color: $Font-colour;
              font-size: 14px;
              font-weight: 500;
            }
            .success{
              color: green;
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: center;

            }
            .error{
              color: red;
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: center;
            }
          }
          button {
            background: $button-colour;
            text-align: center;
            width: 100%;
            padding: 1.5rem 2rem;
            margin-top: 0.5rem;
            border-radius: 0.5rem;
            color: #fff;
            margin-top: 0.5rems;
          }
        }
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .right-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}
