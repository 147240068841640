@import "../../assets/varibles.scss";
@import "../../assets/responsive.scss";
.section4 {
  @include md {
    height: 100%;
  }
  .section4Container {
    h1 {
      color: $Font-colour;
      font-size: 24px;
      font-weight: 700;
      @include lg {
        text-align: left;
      }
      @include md {
        text-align: center;
      }
    }
    .accord { 
      padding: 1rem;
      margin-bottom: 20px;
      border-radius: 21px;
      outline: none;
      background: $Background-color;
      color: $Font-colour;
      text-align: left;
      &::before {
        display: none;
      }
      .accordTitle {
        font-size: 16px;
        font-weight: 600;
      }
      .accordDes{
        margin-top: -20px;
      }
      &.Mui-expanded {
        background: rgba(244, 93, 1, 0.5);
        background-size: cover;
        margin-bottom: 10px;
      }
    }
  }
}
