// varibles
$md: 768px;
$lg: 1024px;
@mixin md {
  @media screen and (max-width: $md) {
    @content;
  }
}
@mixin lg {
  @media screen and (max-width: $lg) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width:1400px ) {
    @content;
  }
}
@mixin xxl {
  @media screen and (min-width:1600px ) {
    @content;
  }
}
@mixin xxxl {
  @media screen and (min-width:1800px ) {
    @content;
  }
}
