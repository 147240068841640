@import "../../assets//varibles.scss";
@import "../../assets//responsive.scss";
.section2 {
  margin-top: 1rem;
  height: 100vh;
  display: flex;

  @include lg {
    height: 100%;
  }
  .section2Container {
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include md {
        flex-direction: column;
      }
      .left {
        width: 50%;
        color: $Font-colour;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @include md {
          width: 100%;
        }
        h2 {
          color: $Font-colour;
          font-size: 24px;
          font-weight: 700;
          @include lg {
            text-align: left;
          }
          @include md {
            text-align: center;
          }
        }
        p {
          font-size: 14px;
          font-weight: 400;
          @include md {
            text-align: center;
          }
        }
      }
      .right {
        width: 40%;
        @include md {
          display: none;
        }
        img {
          width: 100%;
        }
      }
    }
    .bottom {
        @include md {
          margin-top: 60px;
        }
      h2 {
        color: $Font-colour;
        font-size: 24px;
        font-weight: 700;
        @include md {
          text-align: center;
        }
      }
      .itemContainer {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        @include md {
          flex-direction: column;
        }
        @include lg {
          flex-wrap: wrap;
          gap: 1rem;
          justify-content: space-evenly;
        }
        .item {
          padding: 2rem 1.5rem;
          flex-basis: 22%;
          background-color: $Background-color;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 0.2rem;
          @include lg {
            flex-basis: 40%;
          }
          .icon {
            width: 3rem;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(244, 93, 1, 0.5);
            border-radius: 50%;
            img {
              width: 70%;
            }
          }
          h2 {
            text-align: center;
            color: $Font-colour;
            font-size: 18px;
            font-weight: 500;
          }
          p {
            color: $Font-colour;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
