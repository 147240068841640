@import "../../assets//varibles.scss";
@import "../../assets//responsive.scss";
.section3 {
  padding: 0 0 5rem 0 ;
  .section3Container {
    h1 {
      color: $Font-colour;
      font-size: 24px;
      font-weight: 700;
      @include lg {
        text-align: left;
      }
      @include md {
        text-align: center;
      }
    }
    .itemsContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include md {
        flex-direction: column;
        gap: 1rem;
      }
      .item {
        background-color: $Background-color;
        flex-basis: 48%;
        padding: 0.6rem 4rem;
        h2 {
          color: $Font-colour;
          font-size: 22px;
          font-weight: 500;
          padding: 0.5rem;
          border-bottom: 1px solid #d9d9d9;
        }
        .item-container {
          display: flex;
          @include lg {
            flex-direction: column;
          }
          .left {
            flex: 3;
            p {
              color: $Font-colour;
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 0.4rem;
              position: relative;
              &::before {
                content: "";
                width: 1rem;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background-color: rgba(244, 93, 1, 0.1);
                position: absolute;
                top: 10%;
                left: -10%;
              }
            }
          }
          .right {
            flex: 1;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
