@import "../../assets/varibles.scss";
@import "../../assets/responsive.scss";
.footer {
  background-color: $Font-colour;
  .footerContainer {
    display: flex;
    justify-content: space-between;
    padding: 4rem 0;
    gap: 3rem;
    @include lg {
      gap: 3rem;
    }
    @include md {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
  .left,
  .center,
  .right {
    width: 25%;
    @include md {
      width: 100%;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    p,
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }
    span {
      font-weight: 600;
    }
    .icons {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 1rem;
      a {
        width: 10%;
        img {
          width: 100%;
        }
      }
    }
  }
  .center {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    h4 {
      font-size: 18px;
      margin-bottom: 1.5rem;
      @include md {
        text-align: center;
      }
    }
    p {
      font-size: 12px;
      margin-bottom: 0.75rem;
      @include md {
        text-align: center;
      }
    }
  }
  .right {
    h1 {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 1rem 0;
      @include md {
        text-align: center;
      }
    }
    .wrapper {
      width: 100%;
      display: flex;
      gap: 1rem;
      @include md {
        justify-content: center;
      }
      div {
        width: 20%;
        padding: 0.7rem;
        border-radius: 0.5rem;
        background-color: #fff;

        @include lg {
          width: 40%;
        }
        @include md {
          width: 20%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
